.Earn-tabs,
.Funded-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.6rem;
}

.Earn-tabs .Tab.tailwind,
.Funded-tabs .Tab.tailwind {
  overflow-x: auto;
  gap: 30px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--pallete-border);
}
.Earn-tabs .Tab-option,
.Funded-tabs .Tab-option {
  padding-inline: 0 !important;
  font-size: 24px;
}

.Earn-tabs .Tab-option:after,
.Funded-tabs .Tab-option:after {
  display: none;
}

.Earn-tabs-empty,
.Funded-tabs-empty {
  width: 100%;
  border-bottom: 0.1rem solid var(--pallete-border);
}

.Earn-tab,
.Funded-tab {
  font-size: 2.4rem;
  font-weight: 600;
  padding: 2rem 0 2rem 0;
  border-bottom: 0.1rem solid var(--pallete-border);
  color: var(--pallete-inactive);
  height: 78px;
}

.Earn-tabs .active,
.Funded-tabs .active {
  border-bottom: 0.2rem solid var(--text-color);
  color: var(--text-color);
}

.Tab-content {
  width: 100%;
  gap: 1rem;
}

.Earn-tab-description,
.Funded-tab-description {
  margin-bottom: 2rem;
}
