.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-base);
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background: var(--pallete-background-3);
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}

.Position-tab-number {
  white-space: nowrap;
  font-size: var(--font-xs);
  width: 18px;
  height: 18px;
  font-weight: lighter;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  justify-content: center;
  color: #6c7284;
  -webkit-text-fill-color: initial;
}

.Tab.block .Tab-option:hover {
  color: var(--text-color);
  background: var(--tab-background);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: var(--pallete-background-5-v2-only);
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active span {
  opacity: 1;
  pointer-events: none;
  background: var(--pallete-main);
  color: #6c7284 !important;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Tab.inline::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}

.Tab.exchange {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-base);
  overflow: hidden;
  color: var(--pallete-inactive);
  gap: 1rem;
}

.Tab.exchange .Tab-option {
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  width: 14rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

.Tab.exchange .Tab-option.Swap {
  display: none;
}

.Tab.exchange .Tab-option {
  font-weight: bold;
}

.Tab-option-icon {
  filter: invert(46%) sepia(6%) saturate(1132%) hue-rotate(188deg) brightness(93%) contrast(86%);
}

.Tab.exchange .Tab-option.Long {
  background-color: rgba(1, 145, 90, 0.1);
  width: 100%;
}

.Tab.exchange .Tab-option.Short {
  background-color: rgba(255, 89, 89, 0.1);
  width: 100%;
}

.Tab.exchange .Tab-option.Long.active {
  background-color: rgba(0, 192, 117, 1);
  pointer-events: none;
}

.Tab.exchange .Tab-option.Long .Tab-option-swapbox {
  color: rgba(1, 145, 90, 1);
}

.Tab.exchange .Tab-option.Long.active .Tab-option-swapbox {
  color: white;
}

.Tab.exchange .Tab-option.Short.active {
  background-color: rgba(253, 64, 64, 1);
}

.Tab.exchange .Tab-option.Short.active .Tab-option-swapbox {
  color: white;
}

.Tab.exchange .Tab-option.Short .Tab-option-swapbox {
  color: rgba(255, 89, 89, 1);
}

.Tab.order {
  display: flex;
  font-size: var(--font-base);
  line-height: 1.5;
  border-radius: 1rem;
  overflow: hidden;
  color: var(--pallete-inactive);
  margin-bottom: 1.3rem;
  background-color: var(--pallete-background-2);
}

.Tab.order .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  width: 100%;

  &.active {
    opacity: 1;
    pointer-events: none;
    background: var(--pallete-background-3);
    color: var(--text-color);
    border-radius: 10px;

    .Tab-option-icon {
      opacity: 1;
    }
  }
}

.Tab.table {
  display: flex;
  font-size: var(--font-base);
  color: rgba(255, 255, 255, 0.7);

  .Tab-option {
    text-align: center;
    padding: 1.5rem;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @media (max-width: 1100px) {
      padding: 0.7rem;
    }

    .Position-tab-number {
      font-size: var(--font-xs);
      font-weight: normal;
    }

    &:hover {
      color: var(--text-color);
    }

    &.active {
      position: relative;
      font-weight: bold;
      opacity: 1;
      pointer-events: none;
      background: var(--pallete-background-5-v2-only);
      color: transparent;
      z-index: 10;
      background: var(--header-text-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    &.active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px; /* Border height */
      background-image: var(--header-text-color);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.Tab.table-left {
  display: flex;
  font-size: var(--font-base);
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  gap: 3rem;

  .Tab-option {
    text-align: left;
    padding: 1.5rem 0;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    display: flex;
    align-items: center;

    .Position-tab-number {
      font-size: var(--font-xs);
      font-weight: normal;
    }

    &:hover {
      color: var(--text-color);
    }

    &.active {
      position: relative;
      font-weight: bold;
      opacity: 1;
      pointer-events: none;
      background: var(--pallete-background-5-v2-only);
      color: transparent;
      background: var(--header-text-color);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    &.active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px; /* Border height */
      background-image: var(--header-text-color);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.Exchange-swap-order-type-tabs {
  border-bottom: 1px solid var(--pallete-border);
  margin-bottom: 1rem;
}

.Tab-option-swapbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

@media (max-width: 1100px) {
  .Tab-option-swapbox img {
    display: none;
  }
}

.Tab-option-label-red {
  color: red !important;
}

