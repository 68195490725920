.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.Checkbox .Checkbox-icon {
  font-size: var(--font-arbitrary-1-25rem);
  color: var(--pallete-main);
  margin-bottom: 0;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: rgba(255, 255, 255, 0.7);
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-base);
  line-height: 2.4rem;
  color: var(--pallete-inactive);
}

.Checkbox-unchecked {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background-color: var(--pallete-background-2);
  border: 1px solid var(--pallete-border);
}

.Checkbox-checked {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background-color: var(--pallete-main);

  display: flex;
  align-items: center;
  justify-content: center;
}

.checked-icon {
  z-index: 10;
  width: 10px;
  height: auto;
}
