/* Use this file for custom fonts */

@font-face {
  font-family: "Mazzard H ExtraBold";
  src: local(""), url("fonts/Mazzard/MazzardH-ExtraBold.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Bold";
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Bold'), url('fonts/Gotham-Bold/Gotham-Bold.woff') format('woff')
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Inter'), url('fonts/Inter/Inter-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Inter';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: local('Inter'), url('fonts/Inter/Inter-Medium.ttf') format('truetype')
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Inter'), url('fonts/Inter/Inter-Bold.ttf') format('truetype')
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Helvetica-Neue'), url('fonts/Helvetica-Neue/HelveticaNeue-regular.woff') format('woff');
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Helvetica-Neue'), url('fonts/Helvetica-Neue/HelveticaNeue-medium.woff') format('woff');
}
