.Portfolio {
  display: flex;
  flex-direction: row;
}

.MainHeaderWrapper {
  display: flex;
  gap: 0.5rem;

  align-items: center;
}

.MainHeader {
  color: var(--pallete-white);
  font-size: 2.4rem;
}

.openAmount {
  height: 22px;
  min-width: 22px;
  background-color: var(--pallete-background-3);
  border-radius: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: var(--font-xs);
}


.HeaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.FilterWrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.Filter {
  width: 16.3rem;
  height: 3.5rem;
  background-color: var(--pallete-background-3);
}

.Pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.PaginationButtonWrapper {
  display: flex;
  gap: 1rem;
}

.PaginationButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.PaginationButton {
  background-color: transparent;
  border: none;
}

.PaginationDropdown {
  display: flex;
  align-items: center;
  gap: 1rem;
}
