.bronze {
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(169, 86, 65, 0.1) 100%);
}
.gold {
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(205, 157, 76, 0.1) 100%);
}
.silver {
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(160, 162, 168, 0.1) 100%);
}
