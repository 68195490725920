.Tabs-Wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.HistoryFilterWrapper {
  display: flex;
  justify-content: space-between;
}

.downloadCSVIcon {
  height: 1.4rem;
  width: auto;
}

.DownloadButton {
  display: flex;
  width: 14rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
  white-space: nowrap;
  border-radius: var(--border-radius-full);
  border: none;
  font-size: var(--font-sm);
}

.DownloadButton:hover {
  background: #dedede;
}

.History-container {
  border-radius: var(--border-radius-sm);
  background: rgba(255, 255, 255, 0.05);
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  width: fit-content;

  margin-bottom: 2rem;
}

.History-container .Tab-option {
  border-radius: var(--border-radius-sm);
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.History-container .active {
  background: rgba(255, 255, 255, 1);
  color: black;
  cursor: unset;
}
