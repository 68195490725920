.connect-wallet-btn {
  background: var(--pallete-main);
  padding: 0.5rem 1.4rem;

  display: inline-flex;

  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  font-size: var(--font-base);
  cursor: pointer;
  border: 1px solid var(--pallete-border);
  border-radius: var(--border-radius-sm);

  .btn-icon.active {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2rem;
  }
  .btn-label {
    font-weight: 400;
    font-size: var(--font-sm);
    margin-left: 0.4rem;
    letter-spacing: 0;
    color: var(--pallete-white);
  }
}

.connect-wallet-btn-mobile.active {
  padding: 1rem;
  background-color: transparent;
  border: 1px solid var(--pallete-border);
}

.connect-wallet-btn-mobile {
  padding: 0.5rem 0.8rem;
  background-color: var(--pallete-main);
  width: 3.8rem;
  height: 3.8rem;
}

.connect-wallet-btn-mobile.active:hover {
  background-color: var(--pallete-background-2) !important;
}

.connect-wallet-btn:hover {
  background-color: #d94f14;
}

@media (max-width: 350px) {
  .connect-wallet-btn {
    height: 3.6rem;
    .btn-icon {
      display: none;
    }
    .btn-label {
      margin-left: 0px;
    }
  }
}
