.Stake-page {
  align-items: center;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 5.2rem;
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
}

.Stake-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Main-content {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 1.5rem;
  width: 100%;
  height: auto;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.Stake-default-container {
  max-width: 126.4rem;
  padding-left: 10.2rem;
  padding-right: 10.2rem;
  margin: 0 auto;
}

.description-buttons {
  display: flex;
  gap: 1rem;
}

.Stake-button {
  border-radius: 15px;
  width: 96px;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.round-button {
  width: 4rem;
}

.Stake-button-white {
  background: var(--pallete-background-7);
  color: var(--opposite-text-color);
}

.Stake-button-white:hover {
  background: #dedede;
}

.Stake-button-green {
  background: #98e178;
  color: black;
}

.Stake-card {
  padding: 2.5rem;
  border-radius: var(--border-radius-md);
  border: 0.1rem solid var(--pallete-border);
  background: var(--pallete-background-5-v2-only);
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 450px) {
    padding: 1.5rem;
  }
}

.Stake-card.tip {
  padding: 1.3rem 1.8rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: transparent;
  border: none;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.Stake-card-wrapper {
  border-radius: var(--border-radius-md);
  border: 0.1rem solid var(--pallete-border);
}

.Stake-card .flex-row {
  gap: 5rem;
  white-space: nowrap;
}

.Stake-card-header-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
}

.Stake-card-top {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
}

.Stake-card-center {
  margin-top: 46px;
  display: flex;
  align-items: center;
}

.Stake-card-bottom {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.Stake-card-title {
  font-size: 2.4rem;
  color: var(--text-color);
  font-weight: 900;
  font-family: var(--font-mazzard);
  line-height: 1.2em;
  margin-bottom: 0.5rem;
}

.Stake-card-subtitle {
  font-size: 1.4rem;
  color: var(--pallete-inactive);
  line-height: normal;
}

.Stake-card-docs-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  color: var(--pallete-main);
  border: none;
  text-decoration: none;

  &:hover {
    color: var(--pallete-main-hover);
  }

  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
}

.Staked-token-color {
  background: linear-gradient(to right, #fa4949, #fabfa3);
}

.Not-staked-token-color {
  background: linear-gradient(to right, #dee377, #f4d434);
}

.Buyback-amount-color {
  background: linear-gradient(to right, #f46930, #cc390c);
}

.usd-tag {
  border-radius: var(--border-radius-full);
  background: var(--pallete-white-transparent-5);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--pallete-white);

  &:hover {
    background: #1A1A1D
  }
}

.gap-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: visible;
}

.Stake-card-apr-wrapper {
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  gap: 20px;
}

.Stake-card-apr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.Stake-card-apr-text {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: var(--font-base);
}

.Stake-card-apr-percent {
  color: var(--pallete-green);
}

.Stake-card-token-icon {
  width: 20px;
  height: auto;
}

.Stake-card-route-wrapper {
  position: relative;
  height: 200px;
  width: 100%;
  z-index: 0;
}

.Stake-card-infobox {
  padding: 5px 10px;
  color: var(--pallete-main);
  font-size: var(--font-sm);
  border-radius: var(--border-radius-xs);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-decoration: none;
  white-space: nowrap;
}

.Tooltip-handle-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

/* infoboxes */

.Stake-card-infobox-second {
  margin-top: 75px;
}

/* Arrows */

.ArrowConnectOne {
  height: 55px;
}

.ArrowConnectTwo {
  height: 55px;
  margin-top: 90px;
}

.Stake-card-arrow-third-one {
  justify-content: flex-end;
  margin-left: -5px;
}

.Stake-card-arrow-third-two {
  margin-top: -15px;
  margin-left: -5px;
}

.Stake-card-arrow-fourth {
  padding-top: 81px;
  margin-left: -7px;
}

.Stake-card-currency-icon {
  width: 32px;
  height: auto;
}

.Stake-card-palm-icon {
  margin-right: 10px;
}

/* Currencies */

.Stake-card-currency {
  font-size: var(--font-base);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px 10px 4px;
  border-radius: 500px;
  width: 99px;
  height: 40px;
  background-color: var(--pallete-border);
  border: 1px solid transparent;
}

.Stake-card-top.isTopHovered .Stake-card-currency-first,
.Stake-card-top.isTopHovered .Stake-card-currency-second {
  border: 1px solid var(--pallete-border);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.Stake-card-top.isTopHovered .Stake-card-infobox-first {
  border: 1px solid var(--pallete-main);
  box-shadow: 0 0 10px rgba(106, 63, 233, 0.4);
}

.Stake-card-top.isTopHovered .Stake-card-arrow-first,
.Stake-card-top.isTopHovered .Stake-card-arrow-second {
  filter: var(--stake-card-arrow);
}

.Stake-card-arrow-third-one.isTopHovered,
.Stake-card-arrow-third-two.isTopHovered,
.Stake-card-arrow-fourth.isTopHovered,
.Stake-card-arrow-third-one.isBottomHovered,
.Stake-card-arrow-third-two.isBottomHovered,
.Stake-card-arrow-fourth.isBottomHovered {
  filter: var(--stake-card-arrow);
}

/*when bottom is hovered*/

.Stake-card-center.isBottomHovered .Stake-card-currency-third,
.Stake-card-center.isBottomHovered .Stake-card-currency-fourth {
  border: 1px solid var(--pallete-border);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.Stake-card-center.isBottomHovered .Stake-card-arrow-fifth,
.Stake-card-center.isBottomHovered .Stake-card-arrow-sixth,
.Stake-card-bottom.isBottomHovered .Stake-card-arrow-seventh {
  filter: var(--stake-card-arrow);
}

.Stake-card-center.isBottomHovered .Stake-card-infobox-third,
.Stake-card-bottom.isBottomHovered .Stake-card-infobox-fourth,
.Stake-card-bottom.isBottomHovered .Stake-card-infobox-fith,
.Stake-card-bottom.isBottomHovered .Stake-card-infobox-sixth {
  border: 1px solid var(--pallete-main);
  box-shadow: 0 0 10px rgba(106, 63, 233, 0.4);
}

.Stake-card-infobox-claim.isBottomHovered,
.Stake-card-infobox-claim.isTopHovered {
  border: 1px solid rgba(152, 225, 120, 0.7);
  box-shadow: 0 0 10px rgba(152, 225, 120, 0.4);
}

.Stake-card-bottom-infoboxes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 50px;
  margin-top: -4px;
}

.Stake-card-chart {
  display: grid;
  grid-template-columns: auto auto;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  & ul {
    @media screen and (max-width: 1000px) {
      flex-direction: row;
    }
  }
}

.Stake-chart-container {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-template-rows: repeat(2, auto);
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    gap: 1.6rem;
  }
  @media screen and (max-width: 767px) {
    gap: 0;
  }

  & .Stake-chart-title {
    grid-column: 1/3;
    grid-row: 1;
    @media screen and (max-width: 1200px) {
      margin-right: 1rem;
    }
    @media screen and (max-width: 1000px) {
      margin-right: 0;
      text-align: center;
    }
  }

  & .Stake-chart-docs {
    grid-column: 2;
    grid-row: 1;
    @media screen and (max-width: 1000px) {
      grid-row: 3;
      grid-column: 1;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      margin-top: 3.3rem;
    }
  }

  & .recharts-responsive-container {
    margin-top: 3.6rem;
    grid-column: 1;
    grid-row: 2;
    margin-right: 1.2rem;
    @media screen and (max-width: 1000px) {
      grid-row: 2;
      margin-right: auto;
      margin-left: auto;
      margin-top: 1.6rem;
    }
  }

  & .Stake-chart-data {
    margin-top: 6rem;
    grid-column: 2;
    grid-row: 2;

    @media screen and (max-width: 1000px) {
      grid-row: 4;
      grid-column: 1;
      margin-top: 2rem;
      margin-right: auto;
      margin-left: auto;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 3.3rem;
    }
  }

  & .Stake-chart-list-wrapper {
    overflow-x: auto;
    @media screen and (max-width: 600px) {
      background-color: var(--pallete-white-transparent-5);
      border-radius: 15px;
      padding: 1rem 1.6rem;
      overflow-x: auto;
    }
  }

  & .Stake-chart-list {
    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      text-align: left;
      justify-content: center;
    }
    @media screen and (max-width: 600px) {
      overflow-x: auto;
      gap: 2.5rem;
      justify-content: left;
    }

    & .Stake-chart-list-item {
      @media screen and (max-width: 600px) {
        flex-shrink: 0;
      }
    }
  }

  & .Stake-chart-buy-btn {
    background: var(--pallete-main);
    color: #000000;
    cursor: pointer;

    &:hover {
      background: rgba(246, 107, 48, 0.1);
      color: var(--pallete-main);
    }

    @media screen and (max-width: 767px) {
      margin-right: auto;
      margin-left: auto;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      text-align: center;
    }
  }
}

.scroll-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
}

.Description-card {
  width: calc(1 / 3 * 100%);
  height: auto;
  padding: 2.5rem;
  min-width: 360px;
  border-radius: 3.5rem;
  background: var(--pallete-background-5-v2-only);
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--pallete-border);
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    min-width: 260px;
  }
}

.Decription-card-page-title {
  font-size: var(--font-xl);
  font-family: var(--font-mazzard);
}

.Description-card-page-subtitle {
  color: var(--pallete-inactive);
  font-size: var(--font-base);
}

.Description-card-rewards-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6.7rem;
  @media screen and (max-width: 999px) {
    gap: 2rem;
  }
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
}

.Description-rewards-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.Description-reward-title {
  font-size: var(--font-base);
  color: var(--pallete-inactive);
}

.Description-reward-amount {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color);
}

.Description-reward-amount-usd {
  color: var(--pallete-inactive);
  font-size: var(--font-base);
}

.Description-card-claim-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 1.6rem;
  color: var(--pallete-rewards-inactive);
  background-color: var(--pallete-border);
}

.inactive-text {
  font-size: 1.4rem;
  color: var(--pallete-inactive);
}

.inactive-text-small {
  font-size: 1.2rem;
  color: var(--pallete-inactive);
}

.white-text {
  font-size: 1.5rem;
  color: var(--text-color);
}

.white-text-big {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 600;
}

.info-block {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex-basis: 140px;
  overflow-wrap: break-word;
}

.popup-infoblock {
  width: 100%;
  white-space: nowrap;
}

.PLPAccordion {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3rem;
  justify-content: flex-start;
  white-space: nowrap;
}

.PLPAccordion > * {
  margin-left: 0;
}

.accordion-title {
  min-width: 70px;
  font-size: var(--font-xl) !important;
}

.info-block .Stake-button {
  margin-top: 1rem;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.Token-icon-wrapper {
  width: 2.5rem;
  height: 1.9rem;
}

.Chevron-icon-container {
  cursor: pointer;
  margin-left: auto;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;

  &.mob {
    grid-row: 4;
    grid-column: 1 / -1;
    margin-left: 0;
    position: relative;
    top: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    border-radius: 500px;
    margin-top: 2rem;
  }
}

.Accordion-head {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.Accordion-head-mobile {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(4, auto);
  gap: 2rem;

  & .apr {
    grid-column: 2;
    grid-row: 1;
    align-items: end;
  }

  & .total-earned {
    grid-column: 1;
    grid-row: 3;
    margin-top: 1rem;
  }

  & .stake-fee {
    grid-column: 2;
    grid-row: 2;
  }

  & .unstake-fee {
    margin-top: 1rem;
  }

  &.vesting {
    grid-template-rows: repeat(2, auto);
  }
}

.Accordion-head > .PLPAccordion {
  margin-right: 8rem;
}

.Card-divider {
  width: 100%;
  margin: 2.6rem 0 1.8rem;
  border-bottom: 0.1rem solid var(--pallete-border);
  transition: margin-top 0.2s ease;
  @media screen and (max-width: 767px) {
    border-bottom-color: transparent;
  }
}

.openFoxAccordion .Card-divider {
  margin-top: 1.8rem;
}

.Accordion-body {
  width: 100%;
  transition: max-height 0.5s ease;
  max-height: 0;
}

.Accordion-body .mob {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.6rem));
  grid-template-rows: repeat(2, auto);
  gap: 1.2rem;

  & .info-block {
    border-radius: 15px;
    background-color: #202024;
    padding: 1rem 1rem 1.4rem;
    align-items: center;
    width: 100%;
  }
}

.openFoxAccordion {
  max-height: 350px;
}

.openEbFoxAccordion {
  max-height: 350px;
}

.openChartAccordion {
  max-height: 580px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.Accordion-head .Token-icon {
  margin-right: 2.4rem;
}

.Vest-modal,
.Earn-modal {
  display: flex;
  flex-direction: column;
  width: 42rem;
}

.Earn-modal .primary-action {
  background: var(--pallete-main);
  border-radius: var(--border-radius-full);
  height: 45px;
  border: none;
  color: white;
  margin-top: 10px;
}

.Stake-modal-center-section,
.Stake-modal-bottom-section,
.Stake-modal-top-section {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-top: 2.3rem;
}

.Earn-modal .Stake-modal-bottom-section {
  margin-top: 6.5rem;
}

.Vest-modal .Stake-modal-center-section {
  margin-top: 1.4rem;
  margin-bottom: 5rem;
  gap: 1.4rem;
}

.Vest-modal .Stake-modal-bottom-section {
  gap: 2.4rem;
}

.Modal-info-card,
.fraction-selector-item,
.Earn-input {
  color: var(--text-color);
  border: 0.1rem solid var(--pallete-border);
  border-radius: var(--border-radius-sm);
  height: 4rem;
  font-size: 1.4rem;
  background: var(--pallete-background-3);
  text-decoration: none;
  padding: 1.25rem;
}

.Modal-info-card {
  width: 100%;
  height: auto;
  padding: 1.2rem 1.4rem;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-md);
}

.Modal-info-card .Token-icon {
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 0.8rem;
}

.Modal-info-card .right-arrow {
  margin-left: auto;
  font-size: 1.6rem;
}

.fraction-selector-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--text-color);
  height: 3.5rem;
  cursor: pointer;
}

.active-duration {
  background: var(--pallete-main) !important;
  border-color: var(--pallete-main);
}

.active-duration-label {
  color: white;
}

.fraction-selector-wrapper {
  display: flex;
  gap: 0.8rem;
}

.label-value {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Stake-modal-info {
  width: 100%;
  border: 0.1rem solid var(--pallete-border);
  border-radius: var(--border-radius-sm);
  display: flex;
  gap: 0.8rem;
  align-items: flex-start;
  padding: 1.8rem 1.4rem;
  font-size: 1.4rem;
  color: var(--pallete-inactive);
}

.Vest-modal .Stake-modal-info {
  border: 0.1rem solid rgba(241, 203, 128, 0.2);
  background-color: rgba(241, 203, 128, 0.05);
  color: var(--pallete-warning);
}

.input-wrapper {
  width: 100%;
  position: relative;
}

.input-wrapper input {
  width: 100%;
}

.input-end-content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 1.25rem;
}

.currency-price {
  line-height: 30%;
}
