@keyframes animate-stroke {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
  }
}
.loader-path {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  animation: animate-stroke 1.5s ease-in-out infinite;
}
@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

.circular-loader {
  animation: rotate 1s linear infinite;
}
