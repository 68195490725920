.SwapBox-collateral-tooltip-text {
  font-weight: normal;
}
.long-button {
  background: var(--pallete-green) !important;
}

.connect-button {
  width: 100%;
}

.short-button {
  background: var(--pallete-red) !important;
}

.long-button:hover,
.short-button:hover {
  box-shadow: none !important;
}

.Exchange-trigger-order-info-tooltip {
  margin-right: 6px;
}
