.PositionDropdown-dots-icon {
  font-size: var(--font-xs);
  border: none;
  display: inline-flex;
  align-items: center;
  margin-top: 0.3rem;
  background: rgba(255, 255, 255, 0);
  border-radius: 2rem;
  padding: 0.5rem;
}
