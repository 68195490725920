.hidden-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  font-size: var(--font-base);
  background: none;
  color: white;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.buttom-container {
  margin-bottom: 1rem;

  width: 100%;
  margin-bottom: calc(1rem + env(safe-area-inset-bottom));
}

.LocalHeaderLink {
  display: flex;
  height: 100%;
  align-items: center;
  height: 5.5rem;

  &:hover {
    color: var(--text-color);
  }

  &.active {
    position: relative;
    font-weight: bold;
    opacity: 1;
    pointer-events: none;
    color: transparent;
    z-index: 10;
    background: var(--header-text-color);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  &.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; /* Border height */
    background-image: var(--header-text-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: 1100px) {
    &.active::after {
      height: 0px; /* Border height */
    }
  }
}
