.network-dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: var(--text-color);
}

.App-header-language {
  margin-left: 0;
}

.network-dropdown-items {
  min-width: 16.8rem;
  right: -0.8rem !important;
}

.network-dropdown-divider {
  margin: 7px 0;
  border-bottom: 1px solid var(--pallete-border);
}

.network-dropdown-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.language-popup .Modal-content {
  width: 32rem;
}

.network-dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  justify-content: center;
  background: var(--pallete-background-2);
}

.network-popup .Modal-content {
  width: 100%;
  max-width: 38rem !important;
  margin: auto;
}

.network-option {
  cursor: pointer;
  background: var(--pallete-background-5-v2-only);
  border: 1px solid var(--pallete-border);
  border-radius: 0.4rem;
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.network-option:last-child {
  margin-bottom: 0;
}

.network-option img {
  width: 2.5rem;
  margin-right: 1.5rem;
}

.network-option span {
  font-size: var(--font-base);
  letter-spacing: 0.029rem;
  color: #fff;
}

.network-option-img-label {
  font-size: var(--font-md);
  line-height: 2rem;
  letter-spacing: 0.029rem;
  color: #fff;
}

.language-modal-item {
  border: 1px solid var(--pallete-border);
  cursor: pointer;
  height: 50px;
  justify-content: flex-start;
  display: flex;
}

.gas-modal-item {
  border: 1px solid var(--pallete-border);
  cursor: pointer;
  height: 50px;
  justify-content: space-between;
  display: flex;
}

.language-modal-item.active {
  border: 1px solid var(--pallete-border-active);
}
.gas-modal-item.active {
  border: 1px solid var(--pallete-border-active);
  color: var(--text-color);
}

.network-dropdown-headings {
  padding: 0.8rem 0.8rem 0.4rem 0.8rem;
  opacity: 0.7;
  font-size: var(--font-arbitrary-1-25rem);
}

.network-dropdown-item-label {
  margin-left: 0.8rem;
}

.gas-dropdown-menu-item {
  margin-right: 0.8rem;
}

.light-theme-box {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid var(--pallete-border);
}

.gray-theme-box {
  width: 30px;
  height: 30px;
  background-color: #17161E;
  border-radius: 5px;
  border: 1px solid var(--pallete-border);
}

.dark-theme-box {
  width: 30px;
  height: 30px;
  background-color: #0f1014;
  border-radius: 5px;
  border: 1px solid var(--pallete-border);
}

.network-dropdown-list {
  display: grid;
  grid-template-columns: 1fr;
}

.active-dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}
.active-dot.Arbitrum {
  background-color: #4275a8;
}
.active-dot.Avalanche {
  background-color: #e84142;
}
.active-dot.BSC {
  background-color: #f3ba2f;
}

.network-dropdown-label {
  font-size: var(--font-arbitrary-1-25rem);
  margin-bottom: 0.8rem;
  opacity: 0.7;
}

.network-dropdown-list + .menu-label {
  margin-top: 0.7rem;
}

.more-options {
  margin-top: 1rem;
}
