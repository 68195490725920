.gs {

  &-foxify-wrapper {
    position: absolute;
    bottom: 20%;
    left: 15px;
    right: 15px;
    z-index: 10;
    padding: 15px;
    border-radius: 15px;
    background-color: var(--pallete-background-1);
  }

  &-foxify-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &-foxify-progress {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    span{
      margin: 0 5px 0 10px;
      color: var(--pallete-main);
    }

    .circular-progress {
      --size: 25px;
      --half-size: calc(var(--size) / 2);
      --stroke-width: 3px;
      --radius: calc((var(--size) - var(--stroke-width)) / 2);
      --circumference: calc(var(--radius) * var(--pi) * 2);
      --dash: calc((var(--progress) * var(--circumference)) / 100);
    }

    .circular-progress circle {
      cx: var(--half-size);
      cy: var(--half-size);
      r: var(--radius);
      stroke-width: var(--stroke-width);
      fill: none;
      stroke-linecap: round;
    }

    .circular-progress circle.bg {
      stroke: var(--pallete-border);
    }

    .circular-progress circle.fg {
      transform: rotate(-90deg);
      transform-origin: var(--half-size) var(--half-size);
      stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
      transition: stroke-dasharray 0.3s linear 0s;
      stroke: var(--pallete-main);
    }

  }

  &-foxify-checkbox-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: var(--pallete-border);
    margin-right: 10px;

    &.active {
      box-shadow: inset 0 0 0 1px var(--pallete-main);

      svg {
        fill: var(--pallete-main);
      }
    }
  }

  &-foxify-accordion-item{
    margin-bottom: 20px;
  }

  &-foxify-accordion-controller{
    display: flex;

    button{
      margin-left: auto;
    }
  }
}
