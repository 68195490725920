.Sidebar {
  display: flex;
  flex-direction: column;
  width: 26rem;
  background-color: var(--pallete-background-1);
  padding: 10px;
  position: relative;
  border-right: 1px solid var(--pallete-border);
}


.SidebarHeader {
  margin-bottom: 10px;
  color: var(--pallete-inactive);
}

.SidebarItems {
  display: flex;
  flex-direction: column;
  font-size: var(--font-base);
  gap: 10px;
  color: var(--pallete-inactive);
}

.SidebarItem {
  display: flex;
  flex-direction: row;
  height: 4.2rem;
  width: 100%;
  background-color: transparent;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px;
  border-radius: var(--border-radius-sm);
}

.SidebarItem.active {
  background-color: var(--pallete-border);
  color: var(--pallete-white);
}

.Sidebaricons {
  width: 1.8rem;
  height: auto;
}

.SidebarBuy {
  margin-top: auto;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.SidebarBuyButton {
  width: 10.5rem;
  height: 3.5rem;
  background-color: white;
  border-radius: 50rem;
  color: black;
  border: none;
}

.SidebarBuyButton:hover {
  background-color: #dedede;
}

.Sidebaricons {
  filter: invert(47%) sepia(9%) saturate(656%) hue-rotate(187deg) brightness(93%) contrast(91%);
}

.SidebarItem.active .Sidebaricons {
  filter: brightness(1); /* Set hover color */
}
