@media (max-width: 1100px) {
  .Hidden-block {
    display: none;
  }
}
@media (max-width: 1100px) {
  .Modal-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 1100px) {
  .Hidden-button {
    display: block;
  }
}
