.Tooltip-row {
  display: grid;
  margin: 0 0 0.5rem 0;
  grid-template-columns: 1fr auto;
  gap: .5rem;
}

.Tooltip-row > span.label {
  margin-right: 0.5rem;
}
.Tooltip-row > .Tooltip-row-value {
  color: var(--text-color);
  text-align: right;
}

.Tooltip-row-values {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tooltip-row-values li {
  padding: 0.25rem 0 0 0;
  text-align: right;
}

.Tooltip-number {
  color: var(--text-color);
}
