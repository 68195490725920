.InputSearch {
  padding-inline: 1.5rem;
  height: 5rem;
  font-size: var(--font-base);
  line-height: 2;
  background: var(--pallete-background-2);
  width: 100%;
  color: var(--pallete-white);
  border-radius: var(--border-radius-sm);
}

.InputSearch::placeholder {
  color: var(--pallete-inactive);
}

input[type="search"].InputSearch::-webkit-search-decoration,
input[type="search"].InputSearch::-webkit-search-cancel-button,
input[type="search"].InputSearch::-webkit-search-results-button,
input[type="search"].InputSearch::-webkit-search-results-decoration {
  display: none;
}
