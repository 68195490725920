html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  font-size: 10px;
  background-color: var(--pallete-background-1);
}

.App {
  background-color: var(--pallete-background-1);
}

body {
  margin: 0;
  font-family: var(--font-default);
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

#root {
  isolation: isolate;
}

:where(code) {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:where(*) {
  box-sizing: border-box;
  font-family: inherit;
  letter-spacing: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--pallete-background-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #383838;
  border-radius: 1.5rem;
  border: 1px solid #212121;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

:root {
  /* Palmswap fonts */

  --font-default: "Helvetica Neue", sans-serif;
  --font-logo: "Gotham Bold", serif;
  --font-mazzard: "Mazzard H ExtraBold", sans-serif;

  /* Palmswap pallete */

  --pallete-main: #f66b31;
  --pallete-main-hover: #c55528;
  --pallete-main-active: #de612b;
  --pallete-red: #fd4040;
  --pallete-green: rgb(0, 192, 117);
  --pallete-green-with-opacity: rgb(0, 192, 117, 0.1);
  --pallete-white: #ffffff;
  --pallete-warning: #fac709;
  --pallete-white-transparent-5: rgba(255, 255, 255, 0.05);
  --pallete-white-transparent-8: rgba(255, 255, 255, 0.08);

  /* Fonts (pallete) */
  --font-xs: 1rem;
  --font-sm: 1.2rem;
  --font-base: 1.4rem;
  --font-md: 1.6rem;
  --font-lg: 1.8rem;
  --font-xl: 2rem;

  /* Fonts (not pallete / legacy) */
  --font-arbitrary-0-8rem: 0.8rem;
  --font-arbitrary-0-9rem: 0.9rem;
  --font-arbitrary-1-45rem: 1.45rem;
  --font-arbitrary-1-6rem: 1.6rem;
  --font-arbitrary-1-7rem: 1.7rem;
  --font-arbitrary-1-85rem: 1.85rem;
  --font-arbitrary-2-1rem: 2.1rem;
  --font-arbitrary-2-4rem: 2.4rem;
  --font-arbitrary-2-9rem: 2.9rem;
  --font-arbitrary-3-25rem: 3.25rem;
  --font-arbitrary-3-4rem: 3.4rem;
  --font-arbitrary-3-8rem: 3.8rem;
  --font-arbitrary-4-34rem: 4.34rem;
  --font-arbitrary-4-65rem: 4.65rem;
  --font-arbitrary-5-7rem: 5.7rem;

  /* Global styling */

  --dark-blue-bg: var(--pallete-background-1);
  --dark-blue-accent: var(--pallete-inactive);
  --dark-blue-hover: var(--pallete-background-3);
  --avax-red: var(--pallete-red);
  --border-radius-xxs: 5px;
  --border-radius-xs: 7px;
  --border-radius-sm: 10px;
  --border-radius-md: 25px;
  --border-radius-full: 500px;
  --error-red: var(--pallete-red);
  --tab-background: hsl(248, 27%, 42%);

  /* Branded pallets */

  --arbitrum-blue: #28a0f0;

  /* Keep this color to update theme */

  --gtx-primary: hsl(234, 97%, 58%);

  --pi: 3.14159265358979;
}

.gray {
  --pallete-background-1: #0f1014;
  --pallete-background-2: #191b1f;
  --pallete-background-3: #202024;
  --pallete-background-4-hover: #09090c;
  --pallete-background-5-v2-only: #16171d;
  --pallete-background-6: #2e3238;
  --pallete-background-7: #ffffff;
  --pallete-background-8: #16171a;
  --pallete-background-9: #101014;
  --pallete-border: rgba(255, 255, 255, 0.06);
  --pallete-border-active: #7993d0;
  --text-color: #ffffff;
  --opposite-text-color: #000000;
  --text-hover: #eee;
  --stake-card-arrow-filter: brightness(0) invert(1);
  --toast-background: #202327;
}

.light {
  --pallete-background-1: #fafafa;
  --pallete-background-2: #f5f7fb;
  --pallete-background-3: #f6f6ff;
  --pallete-background-4-hover: #ffffff;
  --pallete-background-5-v2-only: #ffffff;
  --pallete-background-6: #e9ebfb;
  --pallete-background-7: #000000;
  --pallete-background-8: #eff1f3;
  --pallete-background-9: #ffffff;
  --pallete-border: #f1f2f6;
  --pallete-border-active: rgba(246, 107, 49, 0.5);
  --text-color: #000000;
  --opposite-text-color: #ffffff;
  --text-hover: #949494;
  --stake-card-arrow-filter: brightness(0);
  --toast-background: #fafafa;
  --header-text-color: linear-gradient(to right, #000000, #000000);
  --pallete-inactive: #9e9e9e;
  --pallete-rewards-inactive: #000;
}

.dark {
  --pallete-background-1: #050608;
  --pallete-background-2: #17171b;
  --pallete-background-2-hover: #101114;
  --pallete-background-3: #202024;
  --pallete-background-3-hover: #1a1a1d;
  --pallete-background-4-hover: #09090c;
  --pallete-background-5-v2-only: #101014;
  --pallete-background-6: #2e3238;
  --pallete-background-7: #ffffff;
  --pallete-background-8: #16171a;
  --pallete-background-9: #101014;
  --pallete-border: rgba(255, 255, 255, 0.08);
  --pallete-border-active: rgba(246, 107, 49, 0.5);
  --text-color: #ffffff;
  --opposite-text-color: #000000;
  --text-hover: #eee;
  --stake-card-arrow-filter: brightness(0) invert(1);
  --toast-background: #202327;
  --header-text-color: linear-gradient(to right, #ffe4d9, #eceaf7);
  --pallete-inactive: #b3b3b3;
  --pallete-rewards-inactive: #b3b3b3;
}

a {
  color: white;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: var(--font-xl);
  outline: none;
  background: none;
  border: none;
  padding: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

button {
  outline: none;
  cursor: pointer;
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.465rem;
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  color: var(--pallete-inactive);
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.relative {
  position: relative;
}

.margin-bottom {
  margin-bottom: 0.465rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.31rem;
}

.inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.text-white {
  color: white;
}

.label,
.label a {
  color: rgba(255, 255, 255, 0.7);
}

ul {
  padding-left: 2.325rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1.5rem;
}

a.plain {
  text-decoration: none;
}

.default-container {
  max-width: 126.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5.4rem;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.font-sm {
  font-size: var(--font-sm);
}
.font-base {
  font-size: var(--font-base);
}
.mr-base {
  margin-right: 1.5rem;
}
.default-cursor.App-cta {
  cursor: default;
}
.nowrap {
  white-space: nowrap;
}

.gap-right > *:not(:last-child) {
  margin-right: 1rem;
}

.text-center {
  text-align: center;
}

.font-family-mazzard {
  font-family: var(--font-mazzard);
}

.Common-card {
  border-radius: var(--border-radius-md);
  border: 0.1rem solid var(--pallete-border);
  background-color: var(--pallete-background-5-v2-only);
  color: var(--pallete-inactive);
}

.Common-subtitle {
  color: var(--pallete-white);
  font-size: 2.4rem;
}
