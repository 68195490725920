.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

.switch input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.switch.isSmallSwitch input {
  position: absolute;
  width: 50%;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 1100px) {
  .switch input {
    position: relative;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.switch.isSmallSwitch .slider::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: "";
  width: 19px;
  height: 19px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7993d0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

input:checked + .isSmallSwitch:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
