.Buy {
  align-items: center;
  justify-content: space-between;
}

.Buy-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Buy-container .section-title-content {
  justify-content: flex-start;
}

.Tab-switch {
  position: relative;
}

.Tab-switch:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: left .4s;
  border-radius: var(--border-radius-sm);
  background: rgba(255, 255, 255, 1);
  z-index: 0;
  width: 13rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Tab-switch.left:after {
  left: 0rem;
}

.Tab-switch.right:after {
  left: 50%;
}

.Tab-switch .Tab-option {
  display: inline-block;
  width: 13rem;
  padding: 1rem 0;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: color 200ms;
  font-size: 1.4rem;
  line-height: normal;
  user-select: none;
  text-align: center;
}

.Tab-switch .Tab-option.active {
  color: black;
}

.Tab-container {
  width: 27rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius-sm);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  margin-top: 4rem;
  margin-bottom: 2rem;
}


.description {
  color: var(--pallete-inactive);
  font-size: var(--font-base);
}

.Token-card {
  margin-top: 20px;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--pallete-border);
  padding: 24px;
  padding-right: 100px;
  background: var(--pallete-background-5-v2-only);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 524px;
}

.Token-icon {
  width: 40px;
  height: 40px;
}

.Pancake-icon {
  width: 24px;
  height: 24px;
}

.Token-name {
  color: var(--text-color);
  font-size: var(--font-xl);
  font-weight: 500;
}

.Text-image-container {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.Token-link-container {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--pallete-border);
  padding: 10px 14px;
  background: var(--pallete-background-3);
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.Token-link-container .Text-image-container {
  cursor: pointer;
}
.Token-link-container .Token-name {
  font-size: var(--font-base);
}
.Token-link-container .description {
  font-size: var(--font-sm);
}

.Buy-plp-modal .Modal-content {
  width: 47rem;
  border-radius: 2.5rem !important;
  border: 1px solid var(--pallete-border);
}

.Buy-plp-modal .Modal-content .Modal-body {
  width: 100%;
}

.Buy-checkbox {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  z-index: -10;
  opacity: 0;
}

.Buy-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.Buy-checkbox + label::before {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--pallete-border);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background: var(--pallete-background-2);
  content: "";
}

.Buy-checkbox:checked + label::before {
  border: 0.1rem solid var(--pallete-main);
  background: var(--pallete-main);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.Buy-fieldset > .Exchange-swap-section.buy-input {
  min-height: fit-content;
}
