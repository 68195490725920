.menu-items:focus-visible {
  border: 1px solid #262638;
}

.menu-items {
  min-width: 15.5rem;
  transform-origin: top right;
  border-radius: 1.5rem;
  list-style: none;
  outline: none;
  z-index: 1000;
  padding: 7px 7px;
  border: 1px solid var(--pallete-border);
  border-radius: var(--border-radius-sm);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.menu-item {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-base);
  color: var(--pallete-inactive);
  font-size: var(--font-base);
  padding: 0.85rem 0.8rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}
.menu-item:hover {
  background: var(--pallete-background-3) !important;
  opacity: 1;
  color: var(--text-hover);
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

.menu-item-group {
  display: flex;
  align-items: center;
  padding-right: 0.8rem;
}

.menu-item {
  padding: 0.8rem 0.9rem;
}

.menu-item + .menu-item {
  margin-top: 0.7rem;
}

.menu-item-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
}

.menu-item:hover .menu-item-label {
 color: var(--text-hover);
}

.menu-label {
  color: var(--pallete-inactive);
  margin: 0 0.7rem 0.7rem;
  font-size: var(--font-sm);
  line-height: 1.3;
}
