.Bridge-from-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  font-size: 1.4rem;
  width: 100%;
}

.bridge-button {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: none;
  border-radius: 5px;
  width: max-content;
  background-color: var(--pallete-background-1);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;
}

.bridge-button:hover {
  background-color: var(--pallete-background-3-hover);
}

.bridge-button-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: none;
  padding: 0.5rem;
  padding-right: 1rem;
  font-size: var(--font-base);
  background-color: var(--pallete-white-transparent-5);
  border-radius: 50rem;
  font-size: 1.4rem;
}

.bridge-button-selector:hover {
  background-color: var(--pallete-white-transparent-8);
}

.bridge-image {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
}

.Bridge-dropdown-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.Bridge-dropdown-wrapper:first-child {
  margin-top: 0;
}

/* Styles for the "Bridge from" text */
.Bridge-dropdown-wrapper p {
  color: var(--text-color);
}

.Bridge-search-input {
  height: 4rem;
  width: 100%;
  font-size: 1.4rem;
}

.dropdown-search {
  padding-left: 1rem;
  padding-right: 1rem;
}

.dropdown-wrapper {
  position: relative;
  border-radius: 1rem;
  padding-left: 1rem;
}

/* Styles for the dropdown options */
.dropdown-options {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: var(--pallete-background-5-v2-only);
  border: 1px solid var(--pallete-border);
  border-radius: 1rem;
  width: 26rem;
  z-index: 1;
  overflow: scroll;
  max-height: 26rem;
  margin-top: 0.5rem;
}

/* Styles for individual dropdown items */
.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  color: var(--text-color);
  border: 1px solid transparent;
  font-size: 1.4rem;
}

.dropdown-options li:hover {
  background-color: var(--pallete-background-3);
}

/* Show the dropdown options when the dropdown is open */
.Bridge-dropdown-wrapper .dropdown-options {
  display: block;
}

.Bridge-information-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.Bridge-information-wrapper {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 1rem;
  font-size: var(--font-base);
  margin-bottom: 1rem;

  background-color: var(--pallete-background-2);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid var(--pallete-border);
}

.Bridge-info-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.Bridge-info-item:first-child {
  margin-top: 0.5rem;
}

.Bridge-info-left {
  display: flex;
  gap: 0.5rem;
}

.Bridge-info-right {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.Tab-container-bridge {
  width: "100%";
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius-sm);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--pallete-inactive);
}

.Tab-switch-bridge {
  position: relative;
  width: 100%;
}

.Tab-switch-bridge:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: left 0.4s;
  border-radius: var(--border-radius-sm);
  background: #25203d;
  z-index: 0;
  width: 16rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Tab-switch-bridge.left:after {
  left: 0rem;
}

.Tab-switch-bridge.right:after {
  left: 50%;
}

.Tab-switch-bridge .Tab-option {
  display: inline-block;
  width: 50%;
  padding: 1rem 0;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: color 200ms;
  font-size: 1.4rem;
  line-height: normal;
  user-select: none;
  text-align: center;
}

.Tab-switch-bridge .Tab-option.active {
  color: var(--pallete-main);
}

.Bridge-swap-section {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--pallete-border);
  background-color: var(--pallete-background-3);
  box-sizing: border-box;
  height: 4rem;
  align-items: center;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Bridge-swap-section:hover {
  border-color: var(--pallete-border-active);
}

.Bridge-swap-section.inputActive {
  border: 1px solid var(--pallete-main);
}

.Bridge-swap-section:first-child {
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
}

.Bridge-swap-section-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: var(--font-base);
}

.Bridge-swap-section-bottom-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: var(--font-base);
  padding-left: 1rem;
  padding-right: 1rem;
}

input.Bridge-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: var(--font-lg);
  width: 100%;
  color: var(--text-color);
  font-size: 1.4rem;
}

input.Bridge-swap-input::placeholder {
  font-size: 1.4rem;
}

input.Bridge-swap-input.small {
  max-width: 100%;
}

.Bridge-warning {
  display: flex;
  flex-direction: column;
  background-color: rgba(250, 190, 0, 0.05);
  padding: 1rem;
  border-radius: var(--border-radius-sm);
  margin-bottom: 1rem;
  gap: 1rem;
  font-size: var(--font-base);
}

.Bridge-warning-header {
  color: var(--pallete-warning);
  display: flex;
  gap: 1rem;
}

.Bridge-top-description-wrapper {
  font-size: var(--font-base);
  color: var(--pallete-inactive);
  text-align: left;
  margin-top: 1rem;
  background-color: var(--pallete-background-3);
  padding: 1rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Bridge-top-description {
  text-decoration: none;
  color: var(--text-color);
}

.Bridge-top-title {
  color: var(--pallete-main);
  font-weight: bold;
  font-size: 16px;
}

.Bridge-from-section {
  background-color: var(--pallete-background-2);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border: 1px solid var(--pallete-border);
  overflow: visible;
}
