.TokenSelector {
  display: flex;
  align-items: center;
}

.TokenSelector-token-name {
  margin-right: 1.5rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector-box {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  font-size: var(--font-sm);
  line-height: 2.4rem;
}

.TokenSelector-box:not(.inactive) {
  cursor: pointer;
}

.TokenSelector-shevron-wrap {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: var(--border-radius-full);
  border: none;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.Token-info .Token-symbol {
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.TokenSelector-tokens {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.TokenSelector-tooltip.Tooltip {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.TokenSelector-tooltip-backing {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.TokenSelector-token-row.disabled {
  cursor: not-allowed;
}

.TokenSelector-token-row.disabled .Token-info {
  opacity: 0.3;
}

.TokenSelector-token-row.disabled .Token-balance {
  opacity: 0.3;
}

.TokenSelector-token-row .Token-name {
  font-size: var(--font-base);
  line-height: 1.5;
  color: var(--text-color);
}

.TokenSelector-token-row:hover {
  background-color: var(--pallete-background-2);
}

.text-accent {
  color: var(--pallete-inactive);
  font-size: var(--font-base);
  line-height: 1.5;
}

.Settings-row {
  cursor: pointer;
  position: relative;
  background: var(--pallete-background-2);
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  height: 50px;
}

.Settings-app-appearance {
  padding: 0 0.8rem 0rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--pallete-inactive);
  font-size: var(--font-base);
}

.line {
  height: 1px;
  background-color: var(--pallete-border);
  padding: 0 0.8rem 0rem 0.8rem;
  width: 100%;
}

.Settings-Layout {
  padding: 0.8rem;
  color: var(--pallete-inactive);
  font-size: var(--font-base);
  display: flex;
  flex-direction: column;
}

.Settings-Layout-active {
  border: 1px solid var(--pallete-main) !important;
  color: var(--text-color);
}

.Settings-item-title-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Settings-Layout-title {
  color: var(--pallete-inactive);
  margin-bottom: 0.5rem;
}

.Setting-Layout-subtitle {
  color: var(--pallete-inactive);
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.Settings-Layout-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Settings-item-icon {
  width: 20px;
  height: auto;
  margin-right: 1rem;
}

.Settings-Layout-left,
.Settings-Layout-right {
  width: 110px;
  height: 62px;
  background-color: var(--pallete-background-2);
  border-radius: 10px;
  border: 1px solid var(--pallete-border);
  margin-left: 5px;
  margin-right: 5px;
  align-items: center;
  display: flex;
  margin-top: 0.8rem;
  cursor: pointer;
}

.Settings-Layout-left {
  justify-content: flex-start;
}
.Settings-Layout-right {
  justify-content: flex-end;
}

.Settings-Layout-panel {
  width: 20px;
  height: 50px;
  background-color: rgba(246, 107, 49, 0.15);
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.Settings-notification-items {
  display: flex;
  flex-direction: column;
}

.Settings-notification-position-top,
.Settings-notification-position-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Settings-notfication-top-left {
  display: flex;
  background-color: red;
}

.Settings-notification-panel {
  width: 110px;
  height: 62px;
  background-color: var(--pallete-background-2);
  border-radius: 10px;
  border: 1px solid var(--pallete-border);
  margin-left: 5px;
  margin-right: 5px;
  align-items: center;
  display: flex;
  margin-top: 10px;
  cursor: pointer;
  justify-content: center;
  position: relative;
}

.NotificationSelected {
  border: 1px solid var(--pallete-main) !important;
  color: var(--text-color);
}

.Settings-notification-bottom-left,
.Settings-notification-bottom-right,
.Settings-notification-top-left,
.Settings-notification-top-right {
  width: 20px;
  height: 9px;
  border-radius: 5px;
  background-color: rgba(246, 107, 49, 0.15);
  position: absolute;
}
.Settings-notification-bottom-left {
  bottom: 5px;
  left: 5px;
}
.Settings-notification-bottom-right {
  bottom: 5px;
  right: 5px;
}
.Settings-notification-top-left {
  top: 5px;
  left: 5px;
}
.Settings-notification-top-right {
  top: 5px;
  right: 5px;
}

.Settings-item-right {
  display: flex;
  align-items: center;
}

.languageImage {
  margin-right: 5px;
}

.Settings-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.Settings-item-title {
  color: var(--text-color);
}

.Settings-item-info {
  color: var(--pallete-inactive);
}

.Settings-item-sub {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: var(--font-base);
  color: var(--pallete-inactive) !important;
}

.Settings-chart-item {
  display: flex;
  padding: 0.5rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.justify-end{
    justify-content: flex-end;
  }
}

.Settings-chart-item p {
  margin: 0;
}
