.FundedTrading-header {
  background-image: url('../../img/funded_trading.png');
  background-size: 808px 123px;
  background-position: center 440%;
  background-repeat: no-repeat;
  @media screen and (max-width: 1023px) {
    background-size: 440px 67px;
    background-position: center -60%;
  }
  @media screen and (max-width: 500px) {
    background-position: center -20%;
  }
}

.FundedTrading-title-wrapper {
  padding-top: 1rem;
  margin: 0 auto;
  color: var(--pallete-inactive);
}

.Create-funded-btn {
  background: var(--pallete-main);
  color: #000000;
  cursor: pointer;

  &:hover {
    background: rgba(246, 107, 48, 0.1);
    color: var(--pallete-main);
  }
}

.Funded-user-data-item {
  background-color: var(--pallete-background-5-v2-only);
  border-radius: 2.5rem;
  border: 1px solid var(--pallete-border);
  padding: 15px;
  &.longer {
    width: 366px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, auto);

    @media screen and (max-width: 1023px) {
      width: 100%;
      grid-template-rows: 1fr;
      grid-template-columns: auto 1fr auto;
    }
  }
  &.shorter {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 173px;
    @media screen and (max-width: 1023px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}