.MarketInformation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--pallete-border);
  background-color: var(--pallete-background-5-v2-only);
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  font-size: var(--font-sm);
  justify-content: space-between;
  z-index: 1001;
}

.MarketInformation-left {
  display: flex;
  height: 100%;
  gap: 1rem;
  align-items: center;
  max-width: 87%;
}
.MarketInformation-right {
  display: flex;
  height: 100%;
  gap: 1rem;
  align-items: center;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--pallete-green);
  border-radius: 50%;
}

.MarketInformation-status {
  background-color: rgba(0, 192, 117, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 1rem;
  color: var(--pallete-green);
  border-radius: 0.8rem;
  gap: 0.5rem;
}

.MarketInformation-filter,
.MarketInformation-version,
.MarketInformation-docs,
.FundedTrading-docs {
  background-color: var(--pallete-background-3) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 1rem;
  border-radius: 0.8rem;
  gap: 0.5rem;
  white-space: nowrap;
  border: none;
  color: var(--text-color);
}

.FundedTrading-docs {
  border-radius: 50rem;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.MarketInformation-filter:hover,
.MarketInformation-version:hover,
.MarketInformation-docs:hover,
.FundedTrading-docs:hover {
  background-color: var(--pallete-background-3-hover) !important;
}

.MarketInformation-markets {
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  max-width: fit-content;
  cursor: pointer;
}

.MarketInformation-market {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
}

.Market {
  display: flex;
  gap: 1rem;
  min-width: 18rem;
}

.Market:first-child {
  margin-left: 1.5rem;
}

.MarketInformation-Menu-Items {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.Filter-Menu-Items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.MarketInformation-Menu-Filter-Wrapper {
  display: flex;
  flex-direction: column;
}

.MarketInformation-Menu-Filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  height: 2.7rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  font-size: var(--font-sm);
}

.MarketInformation-Menu-Filter:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.MarketInformation-Diplay-Option {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: var(--font-sm);
}

.MarketInformation-Menu-Display-Options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}
