.TradeHistory-row {
  font-size: var(--font-base);
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.TradeHistory-time {
  font-size: var(--font-arbitrary-1-25rem);
  margin-bottom: 0.155rem;
}

.TradeHistory-item-link {
  display: inline-block;
}
