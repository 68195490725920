.card {
  border: 1px solid var(--pallete-border);
  border-radius: 0.4rem;
  background: var(--pallete-background-5-v2-only);
}

.card-header {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem;
}

.card-divider {
  height: 1px;
  background: var(--pallete-border);
}
.card-body {
  padding: 1.5rem;
}
