.loading_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.column {
  width: 3px;
  height: 5px;
  background-color: var(--pallete-main);
  margin-left: 10px;
}

.column_1 {
  animation: loadingAnimation 1000ms infinite;
  animation-delay: 100ms;
}
.column_2 {
  animation: loadingAnimation 1000ms infinite;
  animation-delay: 200ms;
}

.column_3 {
  animation: loadingAnimation 1000ms infinite;
  animation-delay: 300ms;
}

.column_4 {
  animation: loadingAnimation 1000ms infinite;
  animation-delay: 400ms;
}

@keyframes loadingAnimation {
  30% {
    transform: scaleY(5);
  }
  60% {
    transform: scaleY(1);
  }
}
