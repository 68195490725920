.chart-token-selector {
  display: flex !important;
  align-items: center;
  padding: 0 !important;
}

.chart-token-selector--current {
  font-size: var(--font-lg);
  line-height: 1.3;
  font-weight: 700;
  margin-right: 1rem;
  color: var(--text-color) !important;
}

.Chart-token-options {
  margin-top: 1rem;
}

.chart-token-menu-items {
  &.menu-items {
    position: absolute;
    width: 347px;
    height: 390px;
    max-height: 390px;
    overflow-y: auto;
    top: 6rem;
    right: unset;
    left: 1rem;
    padding: 1.2rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  &.menu-items-mobile {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 25rem;
  }

  .menu-item {
    font-size: var(--font-base);
    color: white;
    width: 100%;
    height: 6rem;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius-sm);
    &.active {
      background-color: var(--pallete-background-2);
    }
  }

  .menu-item-favorite {
    margin-left: 10px;
    margin-right: 10px;
  }

  .menu-item + .menu-item {
    margin-top: 0.5rem;
  }

  .token-label-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    gap: 5px;
  }

  .token-label {
    font-weight: 700;
    color: var(--text-color);
  }

  .token-label-name {
    color: var(--pallete-inactive);
    font-size: var(--font-sm);
  }

  .token-price {
    color: var(--text-color);
    font-weight: bold;
    font-size: var(--font-base);
  }

  .token-price-delta {
    font-weight: 400;
    margin-left: 1.3rem;

    &.positive {
      color: var(--pallete-green);
    }

    &.negative {
      color: var(--pallete-red);
    }
  }
}

.market-filter-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.market-filter {
  color: var(--pallete-inactive);
  font-size: var(--font-sm);
  border-radius: var(--border-radius-sm);
  border: none;
  background-color: var(--pallete-background-3);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: 3rem;
  margin: 1rem 0rem;
  &.active {
    background-color: #301415;
    color: var(--pallete-main);
  }
}

.market-filter:hover {
  background-color: var(--pallete-background-3-hover);

  &.active {
    background-color: var(--pallete-main);
    color: black;
  }
}

.menu-item-left {
  display: flex;
  align-items: center;
}

.menu-item-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 10px;
  align-items: flex-end;
  font-size: var(--font-sm);
}

.ChartTokenSelector-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 32rem;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .ChartTokenSelector-wrapper {
    height: 5.7rem;
    min-width: 0;
  }
}

.ChartTokenSelector {
  height: 100%;
  display: flex;
  align-items: center;
}

.menu-header {
  color: "white";
  font-size: var(--font-sm);
  font-weight: 400;
}

.menu-header-marketlength {
  color: var(--pallete-inactive);
  font-size: var(--font-sm);
  font-weight: 400;
  margin-left: 5px;
}

.Token-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Token-input {
  min-height: 4.5rem;
  width: 100%;
  background: transparent;
  color: var(--text-color);
  font-size: var(--font-sm);
  padding-left: 1rem;
}

@media (max-width: 1100px) {
  .Token-input {

    background-color: var(--pallete-background-2);
    border-radius: var(--border-radius-sm);
  }
}

.Token-input::placeholder {
  color: var(--pallete-inactive);
}

.Custom-Header {
  cursor: pointer;
  height: 5.4rem;
}

.custom-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.5rem;
  padding: 2rem;
  width: 32rem;
  border-right: 1px solid var(--pallete-border);
}

.custom-header-close {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
