.OrderHeaderWrapper {
  display: flex;
  justify-content: space-between;
}

.cancelOrderButton {
  background-color: var(--pallete-background-3);
  border: 1px solid var(--pallete-border);
  border-radius: var(--border-radius-sm);
  color: var(--pallete-white);
  width: 14rem;
  height: 3.5rem;
}
