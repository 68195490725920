.section-center {
  margin: 0 auto;
  text-align: center;
}
.mt-medium {
  margin-top: 1.5rem;
}

.referral-card {
  position: relative;
  padding: 2.4rem;
  border-radius: 1.3rem;
  max-width: 59.2rem;
  background: var(--pallete-background-5-v2-only);
  box-shadow: 0px 0.5rem 1.3rem #00000085;
  border: 1px solid #2a2b3d;
  border-radius: 0.4rem;
}
.referral-card .title {
  font-weight: 500;
  font-size: var(--font-arbitrary-2-4rem);
  line-height: 3.1rem;
  color: #ffffff;
  padding: 0.775rem 0;
  margin: 0;
}
.referral-card .sub-title {
  font-size: var(--font-lg);
  line-height: 2.5rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.1625rem;
}
.referral-card .card-action {
  margin-top: 3.1rem;
}

.referral-card .card-action input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
  margin-right: 1.5rem;
}

.referral-body-container .list {
  margin-top: 3.1rem;
}

.reward-history {
  margin-top: 3.1rem;
}

.mb-sm {
  margin-bottom: 1.5rem;
}

.error {
  margin: 0;
  padding: 0.775rem 0 1.5rem;
  color: #fa3c58;
  font-size: var(--font-base);
}
