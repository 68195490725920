.wallet-provider {
  color: var(--pallete-inactive);
  margin-left: 5px;
}

.disconnect-btn {
  width: 87px;
  height: 25px;
  border-radius: 6px;
  background-color: rgba(253, 64, 24, 0.15);
  color: var(--pallete-red);
  border: none;
  font-size: var(--font-sm);
}

.disconnect-btn:hover {
  color: white !important;
  background-color: rgba(253, 64, 64, 1);
}

.address-link {
  color: var(--pallete-inactive) !important;
  white-space: nowrap;
  text-decoration: none;
}
