.Overview {
  width: 100%;

  padding-top: 5rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem;
  height: 100%;
  max-height: calc(100vh - 6.2rem);
  overflow-y: auto;
}

.Overview::-webkit-scrollbar {
  display: none; 
}


.ChartInformations {
  display: flex;
  flex-direction: column;
  height: 40.1rem;
  min-width: 20rem;
}



.chartInfo {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.OverviewMaintext {
  font-size: 2.4rem;
  line-height: 1.4em;
}

.OverviewMaintext.profit {
  color: var(--pallete-green);
}

.ChartInfoPercentage {
  cursor: pointer;
  text-decoration: underline;
}



.profitArrow {
  height: 14px;
  width: 14px;
}

.OverviewInfoboxes {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.Infobox {
  display: flex;
  height: auto;

  width: 36rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--pallete-background-5-v2-only);
  border-radius: var(--border-radius-md);
  padding: 2.5rem;
}

.Infoboxtext {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.IconWrapper {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(115, 91, 211, 0.2);
  border: 1px solid rgba(115, 91, 211, 1);
  border-radius: 5rem;
}

.OverviewEarnings {
  margin-top: 2rem;
  gap: 3rem;
  display: flex;
  flex-direction: column;
}

.InfoboxIconBottom {
  height: 5rem;
  width: auto;
}

.OverviewPosition {
  margin-top: 3rem;
  gap: 3rem;
  display: flex;
  flex-direction: column;
}

.OverviewHeaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tooltip{
  border-radius: var(--border-radius-sm);
  background-color: var(--pallete-background-1);
  color: var(--text-color);
  padding: 0.1rem 1rem 0.1rem 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0,0,0,0.5);
  text-align: left;
  border: var(--pallete-border);
}



.IntervalSelector{
  margin-top: 1em;
}

