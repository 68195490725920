.Stake-note {
  text-align: center;
  margin-bottom: 0.465rem;
}

.Stake-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3.1rem;
  grid-gap: 1.5rem;
  margin-top: 0.8rem;
}

.Earn-modal {
  width: 100% !important;
}

.StakeModal .Modal-body {
  font-size: var(--font-base);
}

.Stake-warning {
  text-align: center;
  margin-top: 0.465rem;
}

@media (max-width: 800px) {
  .StakeModal .Modal-content {
    width: auto;
  }

  .Stake-cards {
    grid-template-columns: 1fr;
  }
}

.Stake-checkbox {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  z-index: -10;
  opacity: 0;
}

.Stake-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.Stake-checkbox + label::before {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--pallete-border);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background: var(--pallete-background-2);
  content: "";
}

.Stake-checkbox:checked + label::before {
  border: 0.1rem solid var(--pallete-main);
  background: var(--pallete-main);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.two-column-row {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.info-block-logo {
  display: flex;
}

/* Styles for mobile devices (up to 767 pixels wide) */
@media (max-width: 767px) {
  .Staking-Routes {
    display: none;
  }

  .Stake-default-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .PLPAccordion {
    flex-direction: column;
    gap: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .two-column-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 2rem 0;
    width: 100%;
    justify-content: flex-start;
  }

  .PLPAccordion:nth-child(1) {
    width: 100%;
  }

  .PLPAccordion:nth-child(2),
  .PLPAccordion:nth-child(3) {
    width: 100%;
  }

  /* Styling for the first info-block */
  .two-column-row:nth-child(3) .info-block:first-child {
    order: 2; /* Set the order to 2 (display second in the row) */
  }

  /* Styling for the second info-block */
  .two-column-row:nth-child(3) .info-block:last-child {
    order: 1; /* Set the order to 1 (display first in the row) */
  }


  .Accordion-body-scroll::-webkit-scrollbar {
    display: flex;
  }

  .Accordion-body-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .Accordion-body-scroll::-webkit-scrollbar-thumb {
    background-color: var(--pallete-white);
  }

  .Accordion-body-scroll::-webkit-scrollbar-track-piece {
    background-color: var(--pallete-border);
  }

  .Description-card {
    width: 100%;
    min-width: 0;
    gap: 2rem;
  }

  .Stake-card {
    position: relative;
    overflow-x: scroll;
  }

  .Chevron-icon-container {
    position: absolute;
    top: 2rem;
    right: 2rem;
    margin-left: 2rem;
  }
}

.Stake-top-container .info-block-value{
  color: #fff;
}

.Stake-NFT-card {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 18px 25px 44px;
  background-color: #1D110E;
  border-radius: 25px 25px 0 0;
  margin-bottom: -20px;
}

.Stake-NFT-count {
  color: var(--pallete-main);
}

.NFT-count {
  color: var(--pallete-main);
}

.Stake-NFT-card .info-block{
  flex-basis: auto;
}
