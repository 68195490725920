body {
  overflow-y: hidden; /* Hide vertical scrollbar */
}

body::-webkit-scrollbar {
  display: none;
}

#mava {
  z-index: auto !important;
}

.App {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  font-size: var(--font-base);
}

.App-box,
.App-card,
.App-card-primary,
.App-box-highlight {
  position: relative;
  border: 1px solid var(--pallete-border);
  background-color: var(--pallete-background-5-v2-only);
  font-size: var(--font-base);
}

@media (max-width: 1100px) {
  .App-box {
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }
}

.gray {
  background: var(--pallete-background-5-v2-only);
}

.dark {
  background: transparent;
}

.white {
  color: var(--pallete-white) !important;
}

table.App-box {
  position: relative;
  border: none;
  background: transparent;
}

.App-card-long {
  background: var(--pallete-background-5-v2-only);
  margin-bottom: 2.4rem;
}

.transition-effect {
  transition: all 300ms;
}

.App-card-title-block {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
}

.App-card-title-info-icon {
  display: flex;
  margin-right: 0.8rem;
}

.App-card-title-info {
  display: flex;
}

.App-card-info-title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-info-subtitle {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #a9a9b0;
}

.App-card-title-iconlist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
}

.App-card-title-iconlist___icon img {
  filter: grayscale(1);
}

.App-card-title-iconlist___icon img:hover {
  filter: unset;
}

.Welcome-wallet-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.Connecting-wallet-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}

@media (max-width: 500px) {
  .Connecting-wallet-modal {
    align-items: center;
    padding: 0;
  }
}

.Connecting-wallet-modal {
  .Modal-body {
    display: flex;
    flex-flow: column nowrap;

    .Wallet-btns {
      padding: 2.4rem 0;
      margin-bottom: auto;
    }
  }
}

.Welcome-wallet-modal {
  .Modal-content {
    width: 46rem;
    padding-bottom: 40px !important;
  }
}

.Connecting-wallet-loader {
  margin-top: 1.4rem;
  margin-bottom: 1.1rem;
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Connecting-wallet-btn {
  font-size: var(--font-base);
  color: var(--pallete-main);
  height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
}

.Welcome-wallet-text {
  font-size: var(--font-base);
  text-align: center;
  color: var(--pallete-inactive);
}

.Welcome-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin: 4.5rem auto;
  color: var(--pallete-white);
}

.Welcome-list-item {
  display: flex;
  gap: 2.4rem;
}

.Welcome-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.Welcome-list-title {
  font-size: var(--font-arbitrary-1-6rem);
  font-weight: medium;
  color: var(--text-color);
}

.Welcome-list-description {
  font-size: var(--font-base);
  color: var(--pallete-inactive);
}

.Welcome-icon-container {
  flex-shrink: 0;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-full);
  border: 0.1rem solid var(--pallete-border);
  background: var(--pallete-background-3);
}

.Wallet-btn {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--pallete-background-3);
  border: 1px solid var(--pallete-border);
  border-radius: var(--border-radius-sm);
  margin-top: 1rem;
  position: relative;
  color: white;
  padding: 1.2rem 2rem;
  justify-content: space-between;
}
.Wallet-btn-start {
  display: flex;
  align-items: center;
}
.Wallet-btn:hover {
  background: var(--dark-blue-hover);
  border-color: var(--pallete-border);
}

.Wallet-btn-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-align: start;
  gap: 0.4rem;
}

.Wallet-btn-label {
  font-size: var(--font-base);
  color: var(--text-color);
}

.Wallet-btn-description {
  font-size: var(--font-sm);
  color: var(--pallete-inactive);
  width: fit-content;
  text-align: flex-start;
}

.Wallets-description {
  font-size: var(--font-base);
  color: var(--pallete-inactive);
  text-align: center;
  margin-top: 1rem;
}

.Wallets-description a {
  text-decoration: underline;
  color: var(--pallete-main);
}

.Wallet-btn-end-icon {
  margin-left: 3rem;
}

.MetaMask-btn img {
  display: inline-block;
  height: 2.8rem;
  width: 2.8rem;
  margin-right: 1.4rem;
}

.CoinbaseWallet-btn img {
  display: inline-block;
  height: 2.8rem;
  width: 2.8rem;
  margin-right: 1.4rem;
}

.WalletConnect-btn img {
  display: inline-block;
  height: 2.8rem;
  width: 2.8rem;
  margin-right: 1.4rem;
}

.App-card-divider {
  height: 1px;
  background: var(--pallete-border);
  margin: 1.05rem -1.5rem;
}

.Page {
  max-width: 108.5rem;
  margin: auto;
  padding-top: 4.65rem;
}

.Page-content {
  padding: 4.65rem;
  padding-top: 1.5rem;
}

.section-title-block {
  display: flex;
  margin-bottom: 2.325rem;
  max-width: 58.4rem;
  width: 100%;
}

.page-main-content .section-title-block {
  margin-top: 6.2rem;
}

.section-title-icon {
  /* margin-right: 1.2rem; */
  display: flex;
  align-items: center;
}

.section-title-icon img {
  /* display: flex; */
  display: none;
}

.section-title-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.section-title-content .section-title-content__title {
  font-size: var(--font-arbitrary-2-4rem);
  line-height: 3.1rem;
  color: white;
  letter-spacing: 0px;
}

.section-title-content .section-title-content__description {
  font-size: var(--font-lg);
  line-height: 2.3rem;
  letter-spacing: 0px;
  color: var(--pallete-inactive);
  margin-top: 0.4rem;
  max-width: 62rem;
}

.section-title-content .section-title-content__description span {
  cursor: pointer;
  color: white;
}

.Page-title-section {
  padding-left: 4.65rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
}

.Page-title-section:first-child {
  margin-top: 0px;
}

.Page-title {
  font-size: var(--font-arbitrary-2-4rem);
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.Page-description {
  color: var(--pallete-inactive);
  line-height: 2.15rem;
  font-size: var(--font-base);
}

.Page-description span,
.Page-description a {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: inherit;
}

.Page-description span:hover {
  color: #fff;
  opacity: 0.9;
}

.Page-description span img,
.Page-description a img {
  margin-left: 0.4rem;
}

.App-hero {
  position: relative;
  text-align: center;
  padding-top: 6.2rem;
  margin-bottom: 3.1rem;
}

.App-hero-primary {
  font-size: var(--font-arbitrary-4-65rem);
  font-weight: bold;
  margin-bottom: 1rem;
}

.App-note {
  text-align: center;
  font-weight: bold;
  font-size: var(--font-base);
  padding: 0 3.1rem;
}

.App-card {
  padding: 1.5rem 1.5rem 1.86rem;
  border: 1px solid var(--pallete-border);
  border-radius: 0.4rem;
  font-size: var(--font-base);
}

.App-card-title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-card-title-small {
  font-size: var(--font-md);
  margin-bottom: 1.5rem;
}

.App-card-content {
  display: grid;
  grid-row-gap: 0.8rem;
}

.App-card-row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.5rem;
  padding: unset;
}

.App-card-row.inner {
  padding-left: 1.5rem;
}
.App-card-row .icon {
  vertical-align: baseline;
  margin-left: 0.31rem;
  position: relative;
  top: 0.2rem;
}

.App-card-row > div:last-child {
  text-align: right;
}

.App-card-options {
  padding: unset;
  margin: -0.62rem;
  display: flex;
  gap: 10px;
}

.App-card-option {
  margin: 0.62rem;
}

.Toastify {
  font-size: var(--font-base);
}

.Toastify__toast {
  min-width: 20rem;
  cursor: default;
}

.Toastify__toast-body {
  padding: 0 0.465rem;
  border: var(--pallete-border);
  color: var(--text-color);
}

.Toastify-debug {
  margin-top: 0.465rem;
  font-size: var(--font-arbitrary-1-25rem);
  opacity: 0.7;
}

.Toastify-debug-button {
  display: inline-block;
  border-bottom: 1px dashed;
  cursor: pointer;
}

.Toastify-debug-content {
  margin-top: 0.4rem;
  padding-bottom: 0.8rem;
  max-width: 30rem;
  overflow-x: auto;
}

.App-icon {
  vertical-align: middle;
  margin-bottom: 0.465rem;
}

.App-background-side-1 {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 0.05) 0%, rgba(8, 8, 10, 0) 100%);
}

.App-background-side-2 {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 216, 240, 0.01) 51%, rgba(0, 216, 240, 0.03) 100%);
}

.App-background {
  z-index: 4;
  position: absolute;
  left: -100%;
  right: 0%;
  top: -20%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(80, 10, 245, 0.05) 0%, rgba(80, 10, 245, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-1 {
  z-index: 3;
  position: absolute;
  left: 0%;
  right: 0%;
  top: -50%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(102, 56, 217, 0.05) 0%, rgba(56, 33, 105, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-2 {
  z-index: 2;
  position: absolute;
  left: -50%;
  right: -150%;
  top: -30%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(7, 149, 250, 0.05) 0%, rgba(7, 149, 250, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-base);
  margin-bottom: 0.465rem;
}

.App-info-label {
  opacity: 0.7;
}

.App-cta {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 1.5rem;
  border-radius: 0.3rem;
  border: none;
  font-size: var(--font-base);
}

a.App-cta,
button.App-cta {
  color: white;
  text-decoration: none;
  background: var(--pallete-main);
  &:hover:enabled {
    background: var(--pallete-main-hover);
  }
  &:active:enabled {
    background: var(--pallete-main-active);
  }
}

.App-cta.small {
  padding: 0.9rem 1.1rem;
}

a.App-cta.transparent,
button.App-cta.transparent {
  box-shadow: none;
  background: none;
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

a.App-cta.transparent:hover,
button.App-cta.transparent:hover:enabled {
  color: white;
  box-shadow: none;
  background: none;
}

button.App-cta.option,
a.App-cta.option {
  padding: 0.8rem 1.5rem;
  font-size: var(--font-base);
}

a.App-button-option,
button.App-button-option {
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-base);
  line-height: 2rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: white;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  display: inline-flex !important;
  align-items: center;
  border: none;
  background: hsl(244, 0%, 28%);
}

a.App-button-option:hover,
button.App-button-option:hover:enabled {
  background: hsl(244, 0%, 35%);
}

a.App-button-option:active,
button.App-button-option:active:enabled {
  background: hsl(244, 0%, 41%);
}

button.App-button-option:disabled {
  cursor: not-allowed;
}

.App-settings-row {
  margin-bottom: 0.8rem;
  font-size: var(--font-sm);
  color: var(--pallete-inactive);
}

.App-settings .App-cta {
  margin-top: 1.5rem;
}

.App-slippage-tolerance-input-container {
  position: relative;
}

.App-slippage-tolerance-input {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: var(--font-base);
  width: 100%;
  border-radius: 10px;
  background-color: var(--pallete-background-2);
  height: 50px;
  color: var(--text-color);
}

.App-slippage-tolerance-input-percent {
  position: absolute;
  right: 1.1rem;
  width: 3.1rem;
  top: 2.625rem;
  bottom: 0;
  text-align: right;
  color: var(--pallete-inactive);
}

.App-content {
  position: relative !important;
}

.Toastify__progress-bar--animated {
  background-color: var(--pallete-main) !important;
}

.Toastify__toast--error {
  background: var(--toast-background) no-repeat padding-box;
  color: white;
  border-radius: 0.625rem;
  border: 1px solid var(--pallete-border);
}

.Toastify__toast--success {
  background: var(--toast-background) no-repeat padding-box;
  color: var(--pallete-inactive);
  box-shadow: 0px 0.3rem 0.6rem #0000005c;
  border-radius: 0.625rem;
  border: 1px solid var(--pallete-border);
}

.Toastify__toast--info {
  background: var(--toast-background) no-repeat padding-box;
  color: var(--pallete-inactive);
  box-shadow: 0px 0.3rem 0.6rem #0000005c;
  border-radius: 0.625rem;
  border: 1px solid var(--pallete-border);
}

.Toastify__close-button {
  align-self: flex-start;
  border-radius: 0.4rem;
  width: 16px;
  height: 16px;
  color: var(--pallete-inactive);
  display: flex;
  align-self: center;
  justify-content: center;
  margin-right: 5px;
  transition: none;
}
.Toastify__close-button:hover {
  background: #ffffff47 0% 0% no-repeat padding-box;
}
.Toastify__toast-body {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: var(--font-base);
  line-height: 2rem;
  font-family: var(--font-default);
}

.dropdown-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2rem);
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.positive {
  color: #0ecc83;
}

.long,
.short {
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow: visible;
  align-content: center;
  flex-wrap: nowrap;
  min-width: 6rem;
  border-radius: 8px;
}
.long {
  color: var(--pallete-green);
  background-color: rgba(0, 192, 117, 0.12);
}
.short {
  color: var(--pallete-red);
  background-color: rgba(253, 64, 64, 0.12);
}

.positive-background {
  background: linear-gradient(45deg, rgba(10, 115, 75, 1) 0%, rgba(12, 154, 100, 1) 100%);
}

.negative {
  color: #fa3c58;
}

.negative-background {
  background: linear-gradient(45deg, rgba(115, 30, 43, 1) 0%, rgba(174, 43, 63, 1) 100%);
}

.App-warning,
.App-warning a {
  color: #f037b5;
}

.App-warning {
  padding: 0 3.1rem;
}

.App-slider .rc-slider-rail {
  background-color: var(--pallete-background-3);
  height: 1rem;
  cursor: pointer;
  width: 114%;
  margin-left: -7%;
}

.App-slider .rc-slider-track {
  height: 1rem;
  background: linear-gradient(90deg, transparent 0%, var(--pallete-main) 100%);
  width: 120%;
  margin-left: -7%;
}

@media (max-width: 1100px) {
  .App-slider .rc-slider-rail {
    width: 100%;
    margin-left: 0;
  }

  .App-slider .rc-slider-track {
    width: 100%;
    margin-left: 0%;
  }
}

.App-slider .rc-slider-dot {
  border: none;
  border-radius: 1px;
  width: 2px;
  margin-left: -1px;
  background: transparent;
}

.App-slider .rc-slider-dot-active {
  background: transparent;
}

.App-slider .rc-slider-handle {
  border: solid 2px var(--pallete-main);
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 4px 6px;
  border-radius: 5px;
  background: var(--pallete-background-9);
  font-size: 12px;
  margin-top: 0;
  transform: translateX(-50%) translateY(calc((-50%) + 5px)) !important;
}

.App-slider .rc-slider-handle:active {
  box-shadow: var(--rc-slider);
}

.App-slider .rc-slider-step {
  height: 1rem;
}

.App-slider .rc-slider-mark-text,
.App-slider .rc-slider-mark-text-active {
  pointer-events: none;
  margin-top: 1rem;
  cursor: default;
}

.App-slider .rc-slider-mark-text:hover,
.App-slider .rc-slider-mark-text-active:hover {
  opacity: 1;
}

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: var(--pallete-background-1);
  color: var(--text-color);
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: var(--pallete-background-1);
}

.selector-backdrop {
  opacity: 1;
  height: 100vh;
  z-index: 2;
  backdrop-filter: blur(1.7rem);
  position: fixed;
  top: 0;
  width: 100%;
}

.Tab-title-section {
  padding: 3.1rem 0 0;
  margin-bottom: 2.4rem;
}

.Home-language-menu {
  display: flex;
  align-items: center;
}

.loadButton.svelte-1a0fcx6.svelte-1a0fcx6 {
  bottom: 6rem !important;
}

.chart-positions-settings {
  display: none;
}

@media (max-width: 1100px) {
  .chart-positions-settings {
    display: block;
  }
}
@media (max-width: 1000px) {
  .App-cta.small {
    padding: 0.9rem 0.8rem;
  }

  .Page-title-section {
    margin-top: 3.1rem;
  }
}

@media (max-width: 500px) {
  .header-connect-btn {
    margin-right: 0.4rem;
  }
}

.stars-bg {
  background-image: url("../pages/Event/events/TradeToEarnEvent/icons/StarsBG.svg");
  z-index: 1;
}
